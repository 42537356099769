<template>
  <BaseLayout>
    <AddEditCity @add-edit="$store.dispatch('fsTable/fetchData')" />
    <ContentSection :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus :title="'Cities'" @plus="add" :hide-plus="false" />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem :text="item.name" />
                <FSTableRowItem :text="item.country_name" />
                <FSTableRowItem :text="item.timezone" />
                <FSTableRowItem>
                  <div class="flex items-center">
                    <OtoEditIcon @click="edit(item)" />
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :data="item"
                      @delete="$store.dispatch('fsTable/fetchData')"
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
          </template>
        </FSTable>
      </template>
    </ContentSection>
  </BaseLayout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActionsDropdown from '@/views/country-management/countries/MoreActionsDropdown.vue'
import AddEditCity from '@/views/country-management/cities/AddEditCity.vue'
import { CitiesConfig } from '@/config/CitiesConfig'
import { EventBus } from '@/utils'
export default {
  name: 'Cities',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    MoreActionsDropdown,
    AddEditCity,
  },
  data() {
    return {
      fstId: 'CitiesIndex',
      indexDataEndpoint: CitiesConfig.api.index,
      tableHeaders: [
        {
          text: 'Id',
          width: '20%',
          sort: null,
        },
        {
          text: 'Name',
          width: '20%',
          sort: null,
        },
        {
          text: 'Country',
          width: '20%',
          sort: null,
        },
        {
          text: 'Timezone',
          width: '20%',
          sort: null,
        },
        {
          text: 'Action',
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  methods: {
    add: function() {
      EventBus.$emit(CitiesConfig.events.editingData, {})
      dispatchEvent(new Event(CitiesConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(CitiesConfig.events.editingData, item)
      dispatchEvent(new Event(CitiesConfig.events.sorToggle))
    },
  },
}
</script>
<style lang=""></style>
