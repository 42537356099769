export const CountriesConfig = {
  api: {
    // get
    index: '/dashboard/countries/',
    // post
    create: '/dashboard/countries/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/countries/${id}/`
    },
    //delete
    delete: (id = 'uuid') => {
      return `/dashboard/countries/${id}/`
    },
  },
  events: {
    name: 'countries',
    // refresh-index-data
    refresh: `rid-countries`,
    // slideover-right
    sorId: 'countries',
    sorOpen: 'sor-open-countries',
    sorClose: 'sor-close-countries',
    sorToggle: 'sor-toggle-countries',
    // editing-data
    editingData: 'edit-countries-data',
    // viewing-data
    viewingData: 'view-countries-data',
  },
}
