export const CitiesConfig = {
  api: {
    // get
    index: '/dashboard/cities/',
    // post
    create: '/dashboard/cities/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/cities/${id}/`
    },
    //delete
    delete: (id = 'uuid') => {
      return `/dashboard/currencies/${id}/`
    },
  },
  events: {
    name: 'cities',
    // refresh-index-data
    refresh: `rid-cities`,
    // slideover-right
    sorId: 'cities',
    sorOpen: 'sor-open-cities',
    sorClose: 'sor-close-cities',
    sorToggle: 'sor-toggle-cities',
    // editing-data
    editingData: 'edit-cities-data',
    // viewing-data
    viewingData: 'view-cities-data',
  },
}
