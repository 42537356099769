<template>
  <BaseLayout>
    <AddEditCountry @add-edit="$store.dispatch('fsTable/fetchData')" />
    <ContentSection :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus :title="'Countries'" @plus="add" :hide-plus="false" />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem :text="item.name" />
                <FSTableRowItem :text="item.currency_symbol" />
                <FSTableRowItem :text="item.currency_code_alpha" />
                <FSTableRowItem>
                  <div class="flex items-center">
                    <OtoEditIcon @click="edit(item)" />
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :data="item"
                      @delete="$store.dispatch('fsTable/fetchData')"
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
          </template>
        </FSTable>
      </template>
    </ContentSection>
  </BaseLayout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActionsDropdown from '@/views/country-management/countries/MoreActionsDropdown.vue'
import AddEditCountry from '@/views/country-management/countries/AddEditCountry.vue'
import { CountriesConfig } from '@/config/CountriesConfig'
import { EventBus } from '@/utils'
export default {
  name: 'Countries',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    MoreActionsDropdown,
    AddEditCountry,
  },
  data() {
    return {
      fstId: 'CountriesIndex',
      indexDataEndpoint: CountriesConfig.api.index,
      tableHeaders: [
        {
          text: 'Id',
          width: '20%',
          sort: null,
        },
        {
          text: 'Name',
          width: '20%',
          sort: null,
        },
        {
          text: 'Currency Symbol',
          width: '20%',
          sort: null,
        },
        {
          text: 'Currency Code Alpha',
          width: '20%',
          sort: null,
        },
        {
          text: 'Action',
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  methods: {
    add: function() {
      EventBus.$emit(CountriesConfig.events.editingData, {})
      dispatchEvent(new Event(CountriesConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(CountriesConfig.events.editingData, item)
      dispatchEvent(new Event(CountriesConfig.events.sorToggle))
    },
  },
}
</script>
<style lang=""></style>
