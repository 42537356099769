<template>
  <div class="h-full">
    <gmap-map
      ref="map"
      :zoom="getMapZoom"
      :center="getMapCenterLatLng"
      :options="mapOptions"
      map-type-id="terrain"
      style="width: 100%; height: 100%;"
    >
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  name: 'MapChart',

  props: {
    data: {
      required: true,
    },
  },

  data: () => ({
    parsedPolygon: null,
    polygons: [],
    map_data: [],
  }),

  computed: {
    google: gmapApi,
    mapData: function() {
      return this.data
    },
    getMapCenterLatLng() {
      let centralLatLongsArrayList = []
      for (let i = 0; i < this.data.length; i++) {
        centralLatLongsArrayList.push(
          this.getCenterFromDegrees(this.parseToOverlay(this.data[i].coords))
        )
      }
      const finalCenterPoint = this.getCenterFromDegrees(
        centralLatLongsArrayList
      )
      return {
        lat: finalCenterPoint.lat,
        lng: finalCenterPoint.lng,
      }
    },
    getMapZoom() {
      if (this.data.length >= 1 && this.data.length <= 2) {
        return 6
      } else if (this.data.length >= 3 && this.data.length <= 4) {
        return 4
      } else {
        return 3
      }
    },
  },

  created() {
    this.mapOptions = {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,

      drawingOptions: {
        fillColor: '#09aa35',
        fillOpacity: 0.5,
        strokeWeight: 1,
        strokeColor: '#09aa35',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },

      styles: [
        {
          featureType: 'all',
          elementType: 'geometry.fill',
          stylers: [
            {
              weight: '2.00',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#9c9c9c',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              color: '#f2f2f2',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#7b7b7b',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: '#46bcec',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#c8d7d4',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#070707',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
      ],
    }
  },

  watch: {
    mapData: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        console.log('data = ', updatedData)
        this.initDraw()
      },
    },
  },

  methods: {
    initDraw() {
      var self = this
      setTimeout(() => {
        // console.log('updated')
        if (!self.$refs.map) return
        // console.log('child = ', this.child_area)
        self.$refs.map.$mapPromise.then((map) => {
          // console.log('Hitting promise with', map)
          self.map = map
          self.data.forEach((item) => {
            const overlay = self.parseToOverlay(item.coords)
            self.setOverlayOnMap(overlay)
            //self.setMapCenterToDrawingCenter(overlay, false);
          })

          const bounds = self.map.getBounds()
          self.map.fitBounds(bounds)
        })
      }, 1000)
    },

    getCenterFromDegrees(data) {
      let num_coords = data.length
      let X = 0.0
      let Y = 0.0
      let Z = 0.0
      let lat, lon
      for (let i = 0; i < num_coords; i++) {
        let lat = (data[i].lat * Math.PI) / 180
        let lon = (data[i].lng * Math.PI) / 180
        let a = Math.cos(lat) * Math.cos(lon)
        let b = Math.cos(lat) * Math.sin(lon)
        let c = Math.sin(lat)

        X += a
        Y += b
        Z += c
      }

      X /= num_coords
      Y /= num_coords
      Z /= num_coords

      lon = Math.atan2(Y, X)
      var hyp = Math.sqrt(X * X + Y * Y)
      lat = Math.atan2(Z, hyp)

      var finalLat = (lat * 180) / Math.PI
      var finalLng = (lon * 180) / Math.PI

      return { lat: finalLat, lng: finalLng }
    },

    setOverlayOnMap(payload) {
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })
      this.parsedPolygon.setMap(this.map)

      this.polygons.push(this.parsedPolygon)

      // console.log('SET ON MAP', payload)
    },

    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
  },
}
</script>
