<template>
  <div v-if="HeatmapData != null" class="overflow-x-auto">
    <highcharts :options="HeatmapData" style="min-width:1000px;" />
  </div>
</template>

<script>
import HighCharts from 'highcharts'
import heatmap from 'highcharts/modules/heatmap'
heatmap(HighCharts)

import { Chart } from 'highcharts-vue'
import { formatAMPM } from '@/utils/ConvertDate'

export default {
  name: 'HeatMapChart',
  components: {
    highcharts: Chart,
  },
  props: ['data'],
  computed: {
    getData: function() {
      return this.data
    },
  },
  watch: {
    getData: function(updateData) {
      this.init(updateData)
    },
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(data) {
      this.isLoading = true
      var yAxis = []
      var xAxis = []
      var total = []
      var total_vehicles = []
      var vehicle_percentages = []
      console.log('heatmap chart data = ', data)
      data.forEach((item, x) => {
        yAxis.push(this.getFriendDate(item.date))
        item.data.forEach((el, y) => {
          if (xAxis.length < item.data.length)
            xAxis.push(this.getFriendTime(el.time))
          var obj = [y, x, el.active_vehicle]
          total.push(obj)
          total_vehicles.push(el.total_vehicle)
          vehicle_percentages.push(el.compare)
        })
      })
      this.HeatmapData = {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 0,
          plotBorderWidth: 0,
          style: {
            fontFamily: "'Roboto', sans-serif",
          },
        },

        title: {
          text: '',
        },

        credits: {
          enabled: false,
        },

        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },

        xAxis: {
          categories: xAxis,
          opposite: true,
          title: { text: '' },
          tickWidth: 0,
        },

        yAxis: {
          categories: yAxis,
          title: { text: '' },
          gridLineWidth: 0,
        },

        colorAxis: {
          min: 0,
          minColor: '#eeeeee',
          maxColor: '#114fdf',
        },

        legend: {
          enabled: false,
        },

        tooltip: {
          formatter: function() {
            // console.log("se==", this.point);
            return (
              "<div class='flex flex-wrap items-center' style='width: 200px; height: 128px;'><div class='w-full'><p class='text-base text-gray-600'>" +
              this.series.yAxis.categories[this.point.y] +
              ', ' +
              this.series.xAxis.categories[this.point.x] +
              '</p></div>' +
              "<div class='flex items-center w-full'><div class='w-1/2'><p class='text-lg font-semibold'>Vehicle:</p></div><div class='w-1/2 text-right'><p class='text-lg font-semibold'>" +
              this.point.value +
              "</p></div></div><div class='flex items-center w-full'><div class='w-1/2'><p class='text-base text-gray-600'>" +
              vehicle_percentages[this.point.index].toFixed(2) +
              "% Active</p></div> <div class='w-1/2 text-right'><p class='text-base text-gray-600'>Total: " +
              total_vehicles[this.point.index] +
              '</p></div></div>'
            )
            // return "<p>7 Jul 2020, 6:23 PM</p><h4><span>Vehicle</span><span>10</span></h4><p><span>10% Active</span><span>Total: 100</span></p>";
          },
          padding: 22,
          backgroundColor: '#fff',
          // shadow:false,
          shape: 'square',
          borderRadius: '6px',
          borderWidth: 0,
          useHTML: true,
          className: 'high-tooltip',
        },

        series: [
          {
            name: 'Rents per hour',
            borderWidth: 3,
            borderColor: 'white',
            data: total,
            dataLabels: {
              enabled: true,
              color: '#fff',
              style: {
                textOutline: 0,
              },
              formatter: function() {
                // console.log("Value ==========>", total_vehicles);
                if (this.point.value == 0) {
                  return ''
                } else {
                  let parsedValue = vehicle_percentages[this.point.index]
                  return parsedValue.toFixed(2) + ' %'
                }
              },
            },
          },
        ],
      }
    },
    getFriendDate(date) {
      var time = new Date(date)

      var index = time.getMonth()
      var day = time.getDate()

      var arr_month = new Array()
      arr_month[0] = 'Jan'
      arr_month[1] = 'Feb'
      arr_month[2] = 'Mar'
      arr_month[3] = 'Apr'
      arr_month[4] = 'May'
      arr_month[5] = 'Jun'
      arr_month[6] = 'Jul'
      arr_month[7] = 'Aug'
      arr_month[8] = 'Sep'
      arr_month[9] = 'Oct'
      arr_month[10] = 'Nov'
      arr_month[11] = 'Dec'

      var month = arr_month[index]

      return day + ' ' + month
    },
    getFriendTime(date) {
      return formatAMPM(new Date(date))
    },
  },
  data() {
    return {
      HeatmapData: null,
    }
  },
}
</script>

<style lang="scss">
::v-deep .highcharts-series-hover {
  background: transparent !important;
}
</style>
