<template>
  <t-dropdown variant="calendar">
    <div
      slot="trigger"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
      }"
      class="md:mr-2"
    >
      <button
        class="flex justify-between items-center px-6 mx-2 w-full h-12 text-sm text-gray-700 bg-white rounded-full border shadow-md transition duration-150 ease-in-out dropdown-button-size focus:outline-none focus:shadow-solid"
        aria-label="Calendar"
        aria-haspopup="true"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <div class="flex items-center text-center">
          <svg
            class="hidden w-6 h-6 md:block"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <div v-if="selectedRange.start && selectedRange.end" class="md:ml-5">
            <div class="font-bold text-15px text-mDark">
              <span class="hidden md:inline">{{ dateDiff }}</span>
              <!-- <span class="inline md:hidden">{{ printDate }}</span> -->
            </div>
            <div class="inline font-bold text-15px text-mDark md:hidden">
              {{ dateDiff }} report
            </div>
          </div>
          <div v-else>
            <div class="ml-3 font-bold text-15px text-mDark">Custom report</div>
          </div>
        </div>

        <svg
          class="block mr-4 ml-auto w-6 h-6 md:hidden"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>

        <i class="fas fa-chevron-down"></i>
      </button>
    </div>

    <div
      class="flex justify-between p-1 rounded-md shadow-xs"
      slot-scope="{ hide }"
    >
      <v-range-selector
        :start-date.sync="selectedRange.start"
        :end-date.sync="selectedRange.end"
        class="w-9/12"
      />
      <!--
        Last 7 Days
        Last 28 Days(Default)
        Last 90 Days
        Last 12 month
        Lifetime
      -->
      <div class="flex flex-col justify-between ml-1 w-3/12 bg-gray-100">
        <div>
          <button
            @click="setEasyDatePickRange('today')"
            class="block py-2 px-2 mt-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Today
          </button>
          <button
            @click="setEasyDatePickRange('yesterday')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Yesterday
          </button>
          <button
            @click="setEasyDatePickRange('this-week')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            This Week
          </button>
          <button
            @click="setEasyDatePickRange('last-week')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last Week
          </button>
          <button
            :class="selectedButtonName === 'this-month' && `selected-button`"
            @click="setEasyDatePickRange('this-month')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            This Month
          </button>
          <button
            :class="selectedButtonName === 'last-month' && `selected-button`"
            @click="setEasyDatePickRange('last-month')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last Month
          </button>
          <button
            @click="setEasyDatePickRange('last-7days')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last 7 Days
          </button>
          <button
            @click="setEasyDatePickRange('last-28days')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last 28 Days
          </button>
          <!-- <button
            @click="setEasyDatePickRange('last-90days')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last 90 Days
          </button> -->
          <button
            @click="setEasyDatePickRange('lifetime')"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Lifetime
          </button>
          <button
            @click="resetDateRange()"
            class="block py-2 px-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Custom
          </button>
        </div>
        <div class="flex justify-end items-center">
          <button
            @click="
              hide()
              handleClickingCancel()
            "
            class="flex place-items-center px-4 font-semibold leading-normal uppercase bg-gray-300 rounded border border-transparent transition duration-150 ease-in-out cursor-pointer h-36px text-14px focus:outline-none hover:border-gray-900 hover:text-gray-900 focus:bg-gray-300"
          >
            Cancel
          </button>
          <button
            @click="
              hide()
              handleClickingApplyDate()
            "
            class="flex place-items-center px-4 ml-3 font-semibold leading-normal text-gray-100 uppercase rounded border border-transparent transition duration-150 ease-in-out cursor-pointer h-36px text-14px focus:outline-none bg-oGreen hover:border-green-600 hover:text-white hover:opacity-75 focus:border-green-500"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </t-dropdown>
</template>

<script>
import VRangeSelector from './components/vl-range-selector'
import dayjs from 'dayjs'

var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

var isYesterday = require('dayjs/plugin/isYesterday')
dayjs.extend(isYesterday)

var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

export default {
  name: 'DashboardDatePicker',
  components: {
    VRangeSelector,
  },
  computed: {
    dateDiff: function() {
      if (
        this.selectedRange.start !== null &&
        this.selectedRange.end !== null
      ) {
        const start = dayjs(this.selectedRange.start)
        const end = dayjs(this.selectedRange.end)
        // Do addition of 1 to count the startign-day as well
        let diff
        if (this.selectedRange.isSelected) {
          diff = end.diff(this.selectedRange.start, 'day') + 1
        } else {
          diff = end.diff(this.selectedRange.start, 'day')
        }
        if (start.isToday() && end.isToday()) {
          return "Today's"
        }
        if (start.isYesterday() && end.isYesterday()) {
          return "Yesterday's"
        }
        if (this.selectedRange.start === '2020-01-01' && end.isToday()) {
          return 'Lifetime'
        }
        return 'Last ' + diff + ' days'
      }
      return 0
    },
    startDate: function() {
      const start = this.selectedRange.start
      if (start === this.birthday) {
        return dayjs(this.selectedRange.start).format(this.friendlyFormat)
      }
      return dayjs(start).format(this.friendlyShortFormat)
    },
    endDate: function() {
      return dayjs(this.selectedRange.end).format(this.friendlyFormat)
    },
    printDate() {
      // show only one date when range has same date
      if (this.selectedRange.start === this.selectedRange.end) {
        return dayjs(this.selectedRange.start).format(this.friendlyFormat)
      }
      // show 1 Jan, 2020 - Today -> Lifetime report
      const end = dayjs(this.selectedRange.end)
      if (this.selectedRange.start === '2020-01-01' && end.isToday()) {
        return (
          dayjs(this.selectedRange.start).format(this.friendlyFormat) +
          ' - Today'
        )
      }
      return this.startDate + ' - ' + this.endDate
    },
  },
  data() {
    return {
      selectedRange: {
        start: this.getLast7Day(), //made by guang  //this.getLast28Day(),
        end: this.getToday(),
        isSelected: false,
      },
      format: 'YYYY-MM-DD',
      friendlyFormat: 'DD MMM YYYY',
      friendlyShortFormat: 'DD MMM',
      birthday: '2020-01-01',
    }
  },
  watch: {
    'selectedRange.start'(newValue) {
      this.selectedRange.isSelected = true
      console.log('selectedRange.start', newValue)
    },
    'selectedRange.end'(newValue) {
      this.selectedRange.isSelected = true
      console.log('selectedRange.end', newValue)
    },
  },
  methods: {
    handleClickingApplyDate() {
      this.$emit('apply-date', {
        start: this.selectedRange.start || null,
        end: this.selectedRange.end || null,
      })
      this.$emit('updateChartByDate', this.selectedRange)
    },
    handleClickingCancel() {
      this.selectedRange = {}
      this.$emit('cancel-date', {
        start: null,
        end: null,
      })
    },
    resetDateRange() {
      this.selectedRange = {}
    },
    easyDatePick(option) {
      let range = {}

      const format = this.format
      const today = dayjs()

      if (option === 'today') {
        range.start = today.format(format)
        range.end = today.format(format)
        range.isSelected = true
        // console.log(range);
        return range
      }

      if (option === 'yesterday') {
        const yesterday = today.subtract(1, 'day')

        range.start = yesterday.format(format)
        range.end = yesterday.format(format)
        range.isSelected = true
        // console.log(range);
        return range
      }

      if (option === 'this-week') {
        // this week's last monday-today
        range.start = today.weekday(0).format(format)
        range.end = today.format(format)
        range.isSelected = true
        // console.log(range);
        return range
      }

      if (option === 'last-week') {
        // last week's monday-sunday
        range.start = today.weekday(-6).format(format)
        range.end = today.weekday(0).format(format)
        range.isSelected = true
        return range
      }
      if (option === 'this-month') {
        range.start = today.startOf('month').format(format)
        range.end = today.format(format)
        this.selectedButtonName = 'this-month'
        return range
      }
      if (option === 'last-month') {
        const lastMonth = today.subtract(1, 'month')
        range.start = lastMonth.startOf('month').format(format)
        range.end = lastMonth.endOf('month').format(format)
        this.selectedButtonName = 'last-month'
        return range
      }
      if (option === 'last-7days') {
        range.start = today.subtract(6, 'day').format(format)
        range.end = today.format(format)
        range.isSelected = true
        return range
      }

      if (option === 'last-28days') {
        range.start = today.subtract(27, 'day').format(format)
        range.end = today.format(format)
        range.isSelected = true
        return range
      }

      if (option === 'last-90days') {
        range.start = today.subtract(89, 'day').format(format)
        range.end = today.format(format)
        range.isSelected = true
        return range
      }

      if (option === 'this-month') {
        range.start = today.startOf('month').format(format)
        range.end = today.endOf('month').format(format)
        range.isSelected = true
        // console.log(range);
        return range
      }

      if (option === 'this-year') {
        range.start = today.startOf('year').format(format)
        range.end = today.endOf('year').format(format)
        range.isSelected = true
        // console.log(range);
        return range
      }

      if (option === 'lifetime') {
        // from the birth of the company to today
        range.start = dayjs('2020-01-01').format(format)
        range.end = today.format(format)
        range.isSelected = true
        return range
      }

      return range
    },
    setEasyDatePickRange(option) {
      const validOptions = [
        'today',
        'yesterday',
        'this-week',
        'last-week',
        'last-7days',
        'this-month',
        'last-month',
        'last-28days',
        'last-90days',
        'last-12months',
        'this-year',
        'lifetime', // no date range
        'custom', // custom date range
      ]
      if (!validOptions.includes(option)) {
        this.selectedRange = this.easyDatePick('today')
        return
      }
      this.selectedRange = this.easyDatePick(option)
    },
    getToday() {
      const today = dayjs()
      return today.format(this.format)
    },
    getLast7Day() {
      const last7day = dayjs().subtract(7, 'day')
      return last7day.format(this.format)
    },
    getLast28Day() {
      // const today = dayjs();
      const last28day = dayjs().subtract(27, 'day')
      return last28day.format(this.format)
    },
  },
}
</script>

<style lang="scss">
$vl-primary: #3e3a4e;
$vl-gray-2: #f7fafc;
@import './scss/vuelendar.scss';
.text-calendar {
  color: $vl-primary;
}
.dropdown-button-size {
  width: 260px;
  height: 50px;
}
.text-15px {
  font-size: 15px;
}
.text-10px {
  font-size: 10px;
}
.text-mDark {
  color: #323740;
}
</style>
