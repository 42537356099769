<template>
  <t-dropdown>
    <div
      slot="trigger"
      class="md:mr-2"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
      }"
    >
      <button
        class="flex w-full items-center justify-between h-12 px-6 mx-2 text-sm text-gray-700 transition duration-150 ease-in-out bg-white border rounded-full shadow-md dropdown-button-size focus:outline-none focus:shadow-solid"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <div class="text-left flex items-center">
          <div class="font-bold text-15px text-mDark">
            <span>{{ dropdownText }}</span>
          </div>
        </div>

        <i class="fas fa-chevron-down"></i>
      </button>
    </div>

    <div
      class="items-center  justify-between bg-white py-4 rounded-md shadow-xs max-h-60 overflow-auto"
      slot-scope="{ hide }"
    >
      <div v-for="fleet in fleetData" :key="fleet.id" class="w-full ">
        <button
          class="bg-trasparent outline-none focus:outline-none text-left px-3 py-2 w-full hover:bg-gray-200"
          @click="
            hide()
            updateFleet(fleet.id, fleet.name)
          "
        >
          {{ fleet.name }}
        </button>
      </div>
    </div>
  </t-dropdown>
</template>

<script>
export default {
  name: 'DateRangePicker',
  props: {
    fleetData: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      dropdownText: this.$t('components.homepage.allFleets'),
    }
  },
  methods: {
    updateFleet(id, name) {
      this.dropdownText = name
      this.$emit('getFleetChart', id)
      this.$emit('updateFleets', id)
    },
    addClearFletsToArray() {
      this.fleetData.unshift({
        name: this.$t('components.homepage.allFleets'),
        id: null,
      })
    },
  },
  mounted() {
    this.addClearFletsToArray()
  },
}
</script>

<style lang="scss">
$vl-primary: #3e3a4e;
$vl-gray-2: #f7fafc;
.text-calendar {
  color: $vl-primary;
}
.dropdown-button-size {
  width: 260px;
  height: 50px;
}
.text-15px {
  font-size: 15px;
}
.text-10px {
  font-size: 10px;
}
.text-mDark {
  color: #323740;
}
</style>
