export const CurrenciesConfig = {
  api: {
    // get
    index: '/dashboard/currencies/',
    // post
    create: '/dashboard/currencies/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/currencies/${id}/`
    },
    //delete
    delete: (id = 'uuid') => {
      return `/dashboard/currencies/${id}/`
    },
  },
  events: {
    name: 'currencies',
    // refresh-index-data
    refresh: `rid-currencies`,
    // slideover-right
    sorId: 'currencies',
    sorOpen: 'sor-open-currencies',
    sorClose: 'sor-close-currencies',
    sorToggle: 'sor-toggle-currencies',
    // editing-data
    editingData: 'edit-currencies-data',
    // viewing-data
    viewingData: 'view-currencies-data',
  },
}
